



































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CleanTime from '@/models/CleanTime';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import moment from 'moment';

@Component({})
export default class CleanTimePickerComponent extends mixins(ErrorMessageHandlerMixin) {

    @Prop({default: () => new CleanTime()})
    public selectedCleanTime!: CleanTime;

    public fromTime: string = '';
    public toTime: string = '';

    public fromMenu: boolean = false;
    public toMenu: boolean = false;

    public validationFromError: string = '';
    public validationToError: string = '';

    public allowedMinutes: number[] = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

    constructor() {
        super();
    }

    /*
    TODO: Maybe reintroduce with new JobManage
    public beforeMount() {
    this.updateCleanTime();
    }

    private updateCleanTime() {
    if (this.selectedCleanTime.from) {
        this.fromTime = moment.utc(this.selectedCleanTime.from).format('HH:mm');
    } else {
        this.fromTime = '';
    }

    if (this.selectedCleanTime.to) {
        this.toTime = moment.utc(this.selectedCleanTime.to).format('HH:mm');
    } else {
        this.toTime = '';
    }
    }
    */

    public fromMinuteClicked() {
        this.timeChanged('from');
        (this.$refs.fromMenu as any).save(this.fromTime); // Notify the menu to close
    }

    public toMinuteClicked() {
        this.timeChanged('to');
        (this.$refs.toMenu as any).save(this.toTime); // Notify the menu to close
    }

    @Watch('fromMenu', { deep: true })
    private fromMenuChanged() {
        // Return if we did not close the window
        if (this.fromMenu) {
            return;
        }

        // On Closed save given Time if necessary
        this.onPickerMenuClosed('from');
    }

    @Watch('toMenu', { deep: true })
    private toMenuChanged() {
        // Return if we did not close the window
        if (this.toMenu) {
            return;
        }

        // On Closed save given Time if necessary
        this.onPickerMenuClosed('to');
    }

    private onPickerMenuClosed(key: 'from' | 'to') {
        // Get casted Dates
        const fromCleanTime = this.castTimeToDate(this.fromTime);
        const toCleanTime = this.castTimeToDate(this.toTime);

        // compare if toClean is bigger than fromClean
        if (toCleanTime > fromCleanTime) {
          // Save time for key
          this.timeChanged(key);

          // Set error message to none
          this.validationToError = '';
          this.validationFromError = '';
        } else {
          // Set Error message for the key if the above boolean fails
          const errorStr = this.$t('USER.CLEAN_TIME_ERROR').toString();
          if (key === 'to') {
              this.validationToError = errorStr;
          } else {
              this.validationFromError = errorStr;
          }
        }
    }

    /*
    TODO: Reintroduce maybe with new UserManage
    @Watch('selectedCleanTime', {deep: true})
    private cleanTimeChanged() {
        this.updateCleanTime();
    }
    */
    public castTimeToDate(dateString: string) {
        const newCleanTimeRaw = this.$options.filters!.fromTime(dateString);
        return moment(newCleanTimeRaw).toISOString();
    }

    public timeChanged(key: 'from' | 'to') {
        const fromCleanTime = this.castTimeToDate(this.fromTime);
        const toCleanTime = this.castTimeToDate(this.toTime);

        if (fromCleanTime >= toCleanTime) {
            this.validationFromError = this.$t('USER.CLEAN_TIME_ERROR').toString();
        } else {
            this.validationFromError = '';
        }

        if (!this.toTime) {
            this.validationToError = this.$t('USER.END_TIME_ERROR').toString();
        } else {
            this.validationToError = '';
        }

        this.$emit(`${key}TimeChanged`, key === 'from' ? fromCleanTime : toCleanTime);
    }
}
